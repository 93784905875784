<template>
  <div>
      <Tab :items="items"></Tab>
      <router-view @on-click="onClick" />

      <Dialog class="md-dailog__teacher">
          <components :is="component"></components>
      </Dialog>
  </div>
</template>

<script>
import Tab from '@/components/atom/Tab';
import Dialog from "@/components/atom/Dialog";
import QuestionDetails from "@/components/molecule/question/QuestionDetails";
import QuestionUsedForList from "@/components/molecule/question/QuestionUsedForList";
import CreatedByProfile from "@/components/molecule/user/CreatedByProfile";
import { mapMutations } from "vuex";
export default {
  name: 'TabRouter',
  components: {
      Tab,
      Dialog,
      CreatedByProfile,
      QuestionUsedForList,
      QuestionDetails,
  },
  data() {
    return {
      component: 'QuestionDetails',
      items: [
        {name: 'Reading', path: 'pte-question.reading'},
        {name: 'Writing', path: 'pte-question.writing'},
        {name: 'Speaking', path: 'pte-question.speaking'},
        {name: 'Listening', path: 'pte-question.listening'},
      ]
    }
  },
  methods: {
     ...mapMutations({
      dialog: "setShowDialog",
    }),
    onClick({ component }) {
      this.component = component;
      this.dialog(true);
    }
  },
}
</script>